<template>
    <footer class="footer">
      <a href="https://www.airobotika.com">A.I. Robotika</a> — <a href="http://www.finnhun.com">FinnHun</a>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent',
    // Component logic
  };
  </script>
  
  <style scoped>
 
  </style>
  