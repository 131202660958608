import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomePage.vue';

const routes = [
  { path: '/', component: Home },
  // ... other routes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
