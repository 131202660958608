<template>
  <div id="page-container">
    <div id="content-wrap" class="max-w-screen-lg mx-auto p-4">
      <ClockFaceComponent />
      <DigitalClockComponent />
      <DeviationDisplayComponent />
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import ClockFaceComponent from '../components/ClockFaceComponent.vue';
import DigitalClockComponent from '../components/DigitalClockComponent.vue';
import DeviationDisplayComponent from '../components/DeviationDisplayComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';

export default {
  name: 'HomePage',
  components: {
    ClockFaceComponent,
    DigitalClockComponent,
    DeviationDisplayComponent,
    FooterComponent
  }
};
</script>

<style scoped></style>
