<template>
  <div id="clockContainer">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="00 0 200 200">
      <g font-family="sans-serif">

        <circle id="ptbFaceBackground" cx="50%" cy="50%" r="50%" fill="black" data-fill-connected="black"
          data-fill-disconnected="magenta" />
        <circle cx="50%" cy="50%" r="5" fill="#ffffff" />
        <line x1="89.000000%" y1="50.000000%" x2="100.000000%" y2="50.000000%" stroke="#808080" stroke-width="5" />
        <line x1="94.753485%" y1="54.703781%" x2="99.726095%" y2="55.226423%" stroke="#808080" stroke-width="3" />
        <line x1="94.016642%" y1="59.356026%" x2="98.907380%" y2="60.395585%" stroke="#808080" stroke-width="3" />
        <line x1="92.797543%" y1="63.905765%" x2="97.552826%" y2="65.450850%" stroke="#808080" stroke-width="3" />
        <line x1="91.109546%" y1="68.303149%" x2="95.677273%" y2="70.336832%" stroke="#808080" stroke-width="3" />
        <line x1="83.774991%" y1="69.500000%" x2="93.301270%" y2="75.000000%" stroke="#808080" stroke-width="5" />
        <line x1="86.405765%" y1="76.450336%" x2="90.450850%" y2="79.389263%" stroke="#808080" stroke-width="3" />
        <line x1="83.441517%" y1="80.110877%" x2="87.157241%" y2="83.456530%" stroke="#808080" stroke-width="3" />
        <line x1="80.110877%" y1="83.441517%" x2="83.456530%" y2="87.157241%" stroke="#808080" stroke-width="3" />
        <line x1="76.450336%" y1="86.405765%" x2="79.389263%" y2="90.450850%" stroke="#808080" stroke-width="3" />
        <line x1="69.500000%" y1="83.774991%" x2="75.000000%" y2="93.301270%" stroke="#808080" stroke-width="5" />
        <line x1="68.303149%" y1="91.109546%" x2="70.336832%" y2="95.677273%" stroke="#808080" stroke-width="3" />
        <line x1="63.905765%" y1="92.797543%" x2="65.450850%" y2="97.552826%" stroke="#808080" stroke-width="3" />
        <line x1="59.356026%" y1="94.016642%" x2="60.395585%" y2="98.907380%" stroke="#808080" stroke-width="3" />
        <line x1="54.703781%" y1="94.753485%" x2="55.226423%" y2="99.726095%" stroke="#808080" stroke-width="3" />
        <line x1="50.000000%" y1="89.000000%" x2="50.000000%" y2="100.000000%" stroke="#808080" stroke-width="5" />
        <line x1="45.296219%" y1="94.753485%" x2="44.773577%" y2="99.726095%" stroke="#808080" stroke-width="3" />
        <line x1="40.643974%" y1="94.016642%" x2="39.604415%" y2="98.907380%" stroke="#808080" stroke-width="3" />
        <line x1="36.094235%" y1="92.797543%" x2="34.549150%" y2="97.552826%" stroke="#808080" stroke-width="3" />
        <line x1="31.696851%" y1="91.109546%" x2="29.663168%" y2="95.677273%" stroke="#808080" stroke-width="3" />
        <line x1="30.500000%" y1="83.774991%" x2="25.000000%" y2="93.301270%" stroke="#808080" stroke-width="5" />
        <line x1="23.549664%" y1="86.405765%" x2="20.610737%" y2="90.450850%" stroke="#808080" stroke-width="3" />
        <line x1="19.889123%" y1="83.441517%" x2="16.543470%" y2="87.157241%" stroke="#808080" stroke-width="3" />
        <line x1="16.558483%" y1="80.110877%" x2="12.842759%" y2="83.456530%" stroke="#808080" stroke-width="3" />
        <line x1="13.594235%" y1="76.450336%" x2="9.549150%" y2="79.389263%" stroke="#808080" stroke-width="3" />
        <line x1="16.225009%" y1="69.500000%" x2="6.698730%" y2="75.000000%" stroke="#808080" stroke-width="5" />
        <line x1="8.890454%" y1="68.303149%" x2="4.322727%" y2="70.336832%" stroke="#808080" stroke-width="3" />
        <line x1="7.202457%" y1="63.905765%" x2="2.447174%" y2="65.450850%" stroke="#808080" stroke-width="3" />
        <line x1="5.983358%" y1="59.356026%" x2="1.092620%" y2="60.395585%" stroke="#808080" stroke-width="3" />
        <line x1="5.246515%" y1="54.703781%" x2="0.273905%" y2="55.226423%" stroke="#808080" stroke-width="3" />
        <line x1="11.000000%" y1="50.000000%" x2="0.000000%" y2="50.000000%" stroke="#808080" stroke-width="5" />
        <line x1="5.246515%" y1="45.296219%" x2="0.273905%" y2="44.773577%" stroke="#808080" stroke-width="3" />
        <line x1="5.983358%" y1="40.643974%" x2="1.092620%" y2="39.604415%" stroke="#808080" stroke-width="3" />
        <line x1="7.202457%" y1="36.094235%" x2="2.447174%" y2="34.549150%" stroke="#808080" stroke-width="3" />
        <line x1="8.890454%" y1="31.696851%" x2="4.322727%" y2="29.663168%" stroke="#808080" stroke-width="3" />
        <line x1="16.225009%" y1="30.500000%" x2="6.698730%" y2="25.000000%" stroke="#808080" stroke-width="5" />
        <line x1="13.594235%" y1="23.549664%" x2="9.549150%" y2="20.610737%" stroke="#808080" stroke-width="3" />
        <line x1="16.558483%" y1="19.889123%" x2="12.842759%" y2="16.543470%" stroke="#808080" stroke-width="3" />
        <line x1="19.889123%" y1="16.558483%" x2="16.543470%" y2="12.842759%" stroke="#808080" stroke-width="3" />
        <line x1="23.549664%" y1="13.594235%" x2="20.610737%" y2="9.549150%" stroke="#808080" stroke-width="3" />
        <line x1="30.500000%" y1="16.225009%" x2="25.000000%" y2="6.698730%" stroke="#808080" stroke-width="5" />
        <line x1="31.696851%" y1="8.890454%" x2="29.663168%" y2="4.322727%" stroke="#808080" stroke-width="3" />
        <line x1="36.094235%" y1="7.202457%" x2="34.549150%" y2="2.447174%" stroke="#808080" stroke-width="3" />
        <line x1="40.643974%" y1="5.983358%" x2="39.604415%" y2="1.092620%" stroke="#808080" stroke-width="3" />
        <line x1="45.296219%" y1="5.246515%" x2="44.773577%" y2="0.273905%" stroke="#808080" stroke-width="3" />
        <line x1="50.000000%" y1="11.000000%" x2="50.000000%" y2="0.000000%" stroke="#808080" stroke-width="5" />
        <line x1="54.703781%" y1="5.246515%" x2="55.226423%" y2="0.273905%" stroke="#808080" stroke-width="3" />
        <line x1="59.356026%" y1="5.983358%" x2="60.395585%" y2="1.092620%" stroke="#808080" stroke-width="3" />
        <line x1="63.905765%" y1="7.202457%" x2="65.450850%" y2="2.447174%" stroke="#808080" stroke-width="3" />
        <line x1="68.303149%" y1="8.890454%" x2="70.336832%" y2="4.322727%" stroke="#808080" stroke-width="3" />
        <line x1="69.500000%" y1="16.225009%" x2="75.000000%" y2="6.698730%" stroke="#808080" stroke-width="5" />
        <line x1="76.450336%" y1="13.594235%" x2="79.389263%" y2="9.549150%" stroke="#808080" stroke-width="3" />
        <line x1="80.110877%" y1="16.558483%" x2="83.456530%" y2="12.842759%" stroke="#808080" stroke-width="3" />
        <line x1="83.441517%" y1="19.889123%" x2="87.157241%" y2="16.543470%" stroke="#808080" stroke-width="3" />
        <line x1="86.405765%" y1="23.549664%" x2="90.450850%" y2="20.610737%" stroke="#808080" stroke-width="3" />
        <line x1="83.774991%" y1="30.500000%" x2="93.301270%" y2="25.000000%" stroke="#808080" stroke-width="5" />
        <line x1="91.109546%" y1="31.696851%" x2="95.677273%" y2="29.663168%" stroke="#808080" stroke-width="3" />
        <line x1="92.797543%" y1="36.094235%" x2="97.552826%" y2="34.549150%" stroke="#808080" stroke-width="3" />
        <line x1="94.016642%" y1="40.643974%" x2="98.907380%" y2="39.604415%" stroke="#808080" stroke-width="3" />
        <line x1="94.753485%" y1="45.296219%" x2="99.726095%" y2="44.773577%" stroke="#808080" stroke-width="3" />
        <!-- digital -->
        <g id="ptbSwitchClock" text-anchor="middle" letter-spacing="-0.2" font-size="8px"
          style="fill:#808080;stroke:none;cursor:pointer;">
          <text x="50%" y="24.5%" id="ptbDate"></text>
          <text x="50%" y="32%" id="ptbTime" font-size="16px" font-weight="bold"></text>
          <text x="50%" y="37%" id="ptbLocalTimezone"></text>
        </g>

        <text id="ptbNotice" x="50%" y="69.5%" text-anchor="middle" font-weight="bold" font-size="9px"
          fill="black"></text>
        <!-- deviation -->
        <g id="ptbTabDeviation" class="ptbAct" style="cursor:pointer;" aria-labelledby="ptbDeviationTitle" role="button"
          tabindex="2">
          <title id="ptbDeviationTitle">Show deviation of the local device clock</title>
          <text id="ptbLinkDeviation" text-anchor="middle" x="50%" y="75%"
            style="display:none;fill:#808080;stroke:none;font-weight:bold;font-size:14px;">&Delta;t</text>
          <g id="ptbDeviation" text-anchor="middle" letter-spacing="-0.2" style="display:none;fill:#808080;stroke:none;">
            <text x="50%" y="73%" font-size="9px">The local clock is set to
              <tspan x="50%" y="77.5%" id="ptbOffset" />
              <tspan id="ptbAccuracy" dx="3" font-size="8px" />
            </text>
          </g>
        </g>

        <!-- hour hand - Stundenzeiger -->
        <g id="ptbHourHand" transform="rotate(0,50%,50%)">
          <line x1="50%" y1="50%" x2="50%" y2="20%" stroke="#ffffff" stroke-width="7" stroke-linecap="round" />
        </g>
        <!-- minute hand - Minutenzeiger -->
        <g id="ptbMinuteHand" transform="rotate(0,50%,50%)">
          <line x1="50%" y1="50%" x2="50%" y2="9%" stroke="#ffffff" stroke-width="5" stroke-linecap="round" />
        </g>
        <!-- second hand - Sekundenzeiger -->
        <g id="ptbSecondHand" transform="rotate(0,50%,50%)">
          <line x1="50%" y1="50%" x2="50%" y2="5%" stroke="red" stroke-width="2" stroke-linecap="round" />
          <circle cx="50%" cy="50%" r="2" fill="red" />
        </g>
      </g>
    </svg>
  </div>
</template>
  
<script>
export default {
  name: 'ClockFaceComponent',
  // Component logic
};
</script>
  
<style scoped>
</style>
  